import { centsToDollars, priceTagToCents } from './utils';

export function trackEvent(event, options = {}) {
  if (!window) return
  try {
    if (window.gtag) {
      window.gtag('event', event || 'click', options)
    }
  } catch (e) {
    // console.warn(e)
  }
}

export function sendUserId(userId) {
  if (!window || !userId) return
  try {
    if (window.gtag) {
      window.gtag('set', {
        'user_id': userId,
      })
    }
  } catch (e) {}
}

export function trackEventClick(options = {}) {
  trackEvent('click', options)
}

export function trackSearch(filter) {
  try {
    const { department, merchants, brands, q: query, l } = filter || {} 
    trackEvent( 'search', {
      event_category: 'search',
      searchText: query,
      search_term: query,
      // categories
      department: department?.map(item => item.name)?.filter(n => !!n)?.join(','),
      merchants: merchants?.map(item => item.name)?.filter(n => !!n).join(','),
      brands: brands?.map(item => item.name)?.filter(n => !!n).join(','),
      labels: Array.isArray(l) ? l?.join(',') : l,
    })
  } catch (e) {}
}

export function trackCheckout(product, orderId = '', quantity = 1) {
  try {
    const price = centsToDollars(priceTagToCents(product.priceTag))
    trackEvent('purchase', {
      transaction_id: orderId || '',
      value: price,
      currency: "USD",
      items: [{
        item_id: product.id,
        item_name: product.name || '',
        item_brand: product.brand || '',
        price: price,
        quantity: quantity,
      }],
    })
  } catch (e) {}
}

export function trackViewProduct(product) {
  try {
    const price = centsToDollars(priceTagToCents(product.priceTag))
    trackEvent('view_item', {
      value: price,
      currency: "USD",
      items: [{
        item_id: product.id,
        item_name: product.name || '',
        item_brand: product.brand || '',
        price: price,
      }],
    })
  } catch (e) {}
}

export function handleClick(event, href, options = {}) {
  event.preventDefault()
  trackEventClick(options)
  setTimeout(() => {
    window.location.href = href
  }, 100)
}

function generateIdempotencyKey() {
  const timestamp = Date.now()
  const randomString = Math.random().toString(36).substring(2, 9)
  const idempotencyKey = `${timestamp}-${randomString}`
  return idempotencyKey
}

export function pinTrack(event, data = {}) {
  try {
    if (!window) return
    if (window.pintrk) {
      window.pintrk('track', event, {
        event_id: generateIdempotencyKey(),
        ...data,
      })
    }
  } catch (e) {
    // console.warn(e)
  }
}

export function pinVisit(product) {
  try {
    pinTrack('pagevisit', !!product ? {
      line_items: [{
        product_name: product.name || '',
        product_id: product.id,
        product_price: centsToDollars(priceTagToCents(product.priceTag)),
        product_quantity: 1,
        product_brand: product.brand || '',
      }]
    } : {})
  } catch (e) {}
}

export function pinAddToCart(product) {
  if (!product) return
  try {
    const price = centsToDollars(priceTagToCents(product.priceTag)) || ''
    pinTrack('addtocart', {
      value: price,
      order_quantity: 1,
      currency: 'USD',
      line_items: [{
        product_name: product.name || '',
        product_id: product.id,
        product_price: price,
        product_quantity: 1,
        product_brand: product.brand || '',
      }]
    })
  } catch (e) {}
}

export function pinCheckout(product) {
  if (!product) return
  try {
    const price = centsToDollars(priceTagToCents(product.priceTag))
    pinTrack('checkout', {
      value: price,
      order_quantity: 1,
      currency: 'USD',
      line_items: [{
        product_name: product.name || '',
        product_id: product.id,
        product_price: price,
        product_quantity: 1,
        product_brand: product.brand || '',
      }]
    })
  } catch (e) {}
}