import React, { useState, useEffect, useRef } from 'react';
import * as headers from './header.module.scss';
import LogoBlack from '@images/ic_logo_black.svg';
// import LogoWhite from '@images/ic_logo_white.svg';
import LogoB from '@images/logo_b.svg';
import { Input } from 'antd-mobile';
import iconSearch from '@images/icon_search_gray.svg';
import iconProfile from '@images/icon_profile.png';
// import iconProfileWhite from '@images/icon_profile_white.png';
import { getHotWords } from '@service/api';
import { getAccount, isLogin } from '@service/AccountService';
import Profile from './Profile';
import Department from './Department';
import { useMediaQuery } from 'react-responsive';
import SearchPanel from '@/components/searchPanel';

const pageClassMap = new Map([
  ['HOME', headers.pHome], 
  ['OUTLET', headers.pOutlets], 
  ['LUX', headers.pLux], 
  ['ECO', headers.pEco], 
  ['CATEGORY', headers.pCategory], 
  ['EURO', headers.pEuro]
])

const Header = (props) => {
  const { pageName, query, updateQuery }  = props || {}
  const isMobileScreen = useMediaQuery({
    query: "(max-width: 960px)",
  })
  const rightRef = useRef(null)
  const [value, setValue] = useState(query || '')
  const [isFocus, changeFocus] = useState(false)
  const [profileVisible, setProfileVisible] = useState()
  const account = getAccount()
  const { firstName } = account || {}
  const [hotWords, setHotWords] = useState([])
  const isCheckout = pageName === 'CHECKOUT'

  useEffect(() => {
    if (!isFocus) return
    getHotWords()
      .then((res) => {
        setHotWords(res.hotWords)
      })
      .catch((err) => {
        // console.warn(err)
      })
  }, [isFocus])

  useEffect(() => {
    if (isFocus || profileVisible) {
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.style.height = '100vh'
    } else {
      document.documentElement.style.overflow = 'visible'
      document.documentElement.style.height = 'auto'
    }
  }, [isFocus, profileVisible])

  const doSearch = () => {
    changeFocus(false)
    setValue(value)
    updateQuery(value)
  }

  const getPageClass = () => {
    return pageClassMap.get(pageName) || `common-header-pc`
  }

  const onClickHotWordsItem = (item) => {
    changeFocus(false)
    setValue(item)
    updateQuery(item)
  }

  const profileClick = () => {
    if(isLogin()) {
      setProfileVisible(true)
    } else {
      window.location.href = '/login'
    }
  }

  // const onProfileFocus = () => {
  //   if(isLogin()) {
  //     setProfileVisible(true)
  //   }
  // }

  return (
    <header id='header_pc' className={`${getPageClass()} `}>
      <div className={headers.headerPc}>
        <div className={headers.headerPcLeft}>
          {isCheckout ? (
            <div className={headers.checkoutHeaderTitle}><img src={LogoB} />Check Out</div>
          ) : (
            <div><a href='/' title='homepage'><img src={LogoBlack} alt="logo" /></a></div>
          )}
          {!isCheckout ? (
            <ol className={headers.headerNav}>
              <li><a href='/outlet/' title='outlet mode' className={pageName === 'OUTLET' ? headers.activeRoute : ''}>B-OUTLET</a></li>
              <li><a href='/euro/' title='euro mode' className={pageName === 'EURO' ? headers.activeRoute : ''}>B-EURO</a></li>
              <li><a href='/eco/' title='eco mode' className={pageName === 'ECO' ? headers.activeRoute : ''}>B-ECO</a></li>
              <li>
                <div className={headers.pDepartment}>
                  <span>DEPARTMENTS</span>
                  <div className={headers.department}>
                    {!isMobileScreen ? <Department /> : null}
                  </div>
                </div>
              </li>
            </ol>
          ) : null}
        </div>
        <div className={headers.headerPcRight} ref={rightRef}>
          {!isCheckout ? (
            <div className={headers.pSearchMain}>
              <form className={headers.pForm} target={'frameFile'} onSubmit={doSearch}>
                <Input
                  placeholder={`Search for any product or brand`}
                  value={value}
                  className={headers.searchInput}
                  onFocus={() => changeFocus(true)}
                  onBlur={() => changeFocus(false)}
                  onChange={val => {
                    setValue(val)
                  }}
                />
                <iframe name='frameFile' style={{ display: 'none' }}></iframe>
              </form>
              <div className={headers.pIconSearch} onClick={doSearch}>
                <img src={iconSearch} alt={'icon search'} />
              </div>
            </div>
          ) : null}
          <div className={headers.headerProfile} onClick={profileClick}>
            <img src={iconProfile} alt="icon profile" />
            <div>{isLogin() && firstName ? `Hi ${firstName}` : 'Sign in'}</div>
          </div>
        </div>
        {
          isFocus ? <div className={headers.headerSearchMask} onClick={() => changeFocus(false)}></div> : <></>
        }
        {
          isFocus ? (
            <SearchPanel
              title='Popular' 
              items={hotWords}
              onClickItem={(_, item) => onClickHotWordsItem(item)}
              left={rightRef.current?.offsetLeft - rightRef.current?.clientWidth * 0.2}
              top={rightRef.current?.clientHeight}
              zIndex={999}
            />
          ) : null
        }
      </div>
      <Profile visible={profileVisible} close={() => setProfileVisible(false)} />
    </header>
  )
}

export default Header