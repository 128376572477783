import React, { MouseEventHandler, useState, useMemo } from 'react';
import Mask from '@modules/common/Mask';
import * as mms from './menu_m.module.scss';
import LogoBlack from '@images/ic_logo_black.png';
import icClose from '@images/icon_close_whitebg.png';
import icFilter from '@images/icon_filter_down.png';
import SearchBox from '@modules/category/searchBox';
import { isLogin } from '@service/AccountService';
// import { HOME_DEPARTMENT } from '@lib/constants';
import departmentBundle from '@lib/homeCategories.json';
import IcMore from '@images/icon_more.png';
import IcLess from '@images/icon_less.png';
import { normalizeTerm } from '@lib/utils';
// import { getTermFromCid } from '@/lib/categoryUtils';
// import { LayoutContext } from '@components/Context';

interface IProps {
  visible: boolean
  close: MouseEventHandler<HTMLDivElement>
  clickMenu: Function
  headerHeight: number
}

interface IOpenList {
  [k: string]: boolean
}

const modeList = {
  title: 'Shop by Mode',
  children: [
    {
      title: 'B-OUTLET',
      path: '/outlet/',
    },
    {
      title: 'B-EURO',
      path: '/euro/',
    },
    {
      title: 'B-ECO',
      path: '/eco/',
    },
  ]
}

const otherList = [
  {
    title: 'Shop by Merchant',
    path: '/merchants/',
  },
  {
    title: 'Shop by Brand',
    path: '/brands/',
  }
]

const Menu = (props: IProps) => {
  // const layoutContext: any = useContext(LayoutContext)
  const { clickMenu, close } = props || {}
  const [openList, changeOpenList] = useState<IOpenList>({8: true, 9: true, 'b': true, 'g': true})
  
  const departmentsList = useMemo(() => {
    return {
      title: 'Shop by Department',
      children: departmentBundle.children
    }
  }, [departmentBundle])

  function handleCreareAccount(e: any) {
    if (close) {
      close(e)
    }
    window.location.href = '/register'
  }

  const fold = (id: string) => {
    changeOpenList({ ...openList, [id]: !openList[id]})
  }

  const renderCate = (children: Array<any>, show?: boolean) => {
    return children?.map((cate, i) => {
      const { category } = cate || {}
      let _path = ''
      if (category.path) {
        _path = category.path?.replace('&', '')
      } else {
        _path = !!category.id ? category.term?.replace('&', '') : undefined
      }
      const onClick = (e: any) => {
        e.preventDefault()
        if (!_path) return
        clickMenu(`/category/${normalizeTerm(_path)}/`)
      }
      
      const openKey = _path || category.id || category.term
      return (
        <div key={i+'_category_item_' + category.id} className={show ? mms.childItem : mms.childItemNone}>
          <a 
            className={mms.flexBetweenChild}
            href={!!_path ? `/category/${normalizeTerm(_path)}/` : ''}
            onClick={onClick}
          >
            <span className={mms.categoryItem}>
              {cate.category.term}
            </span>
            {
              cate.children?.length
                ? <img 
                    src={openList[openKey] ? IcLess : IcMore} 
                    alt="menu item filter icon" 
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      fold(openKey)
                    }}
                  />
                : <img src={icFilter} alt="filter arrow" />
            }
          </a>
          {
            renderCate(cate.children, openList[openKey])
          }
        </div>
      )
    })
  }

  return (
    <Mask visible={props.visible} close={close}>
      <>
        <div className={`${mms.lMenuM} ${props.visible ? mms.lMenuMShow : ''}`}>
          <div className={mms.menuMBg} onClick={close}></div>
          <div className={mms.headerBg}>
            <div className={mms.headerM}>
              <div className={mms.headerMImg} onClick={close}></div>
              <div className={mms.headerTitle}>
                <a href='/'><img onClick={close} src={LogoBlack} className={mms.logo} title='logo'></img></a>
              </div>
              <div className={mms.headerMProfile}></div>
            </div>

            <SearchBox />
          </div>
          <div className={mms.menuContainer}>
            {!isLogin() ? <div className={mms.create} onClick={handleCreareAccount}>Create an Account</div> : null}
            <div>
              <div className={mms.flexBetween}>
                <h2>{modeList.title}</h2>
              </div>
              {
                modeList.children.map(item => {
                  return (
                    <a
                      href={item.path}
                      key={'menu_item_' + item.title}
                      className={mms.flexBetweenChild}
                      onClick={(e) => {
                        e.preventDefault()
                        clickMenu(item.path)
                      }}
                    >
                      <h3>{item.title}</h3>
                      <img src={icFilter} alt="filter arrow" />
                    </a>
                  )
                })
              }
            </div>

            <div>
              <div className={mms.flexBetween}>
                <h3>{departmentsList.title}</h3>
              </div>
              {
                renderCate(departmentsList.children, true)
              }
            </div>

            {
              otherList.map((menu, index) => {
                const onClick = (e: any) => {
                  e.preventDefault()
                  clickMenu(menu.path)
                }
                return (
                  <a
                    href={menu.path}
                    key={'menu_' + index} 
                    className={mms.flexBetween}
                    onClick={onClick}
                  >
                    <h3>{menu.title}</h3>
                    <img src={icFilter} alt="filter arrow" />
                  </a>
                )
              })
            }

            {/* <div className={mms.flexBetween}>
              <div>My Account</div>
              <img src={icFilter} alt="filter arrow" />
            </div> */}
            <a href="mailto:contact@beyondstyle.us">
              <div className={mms.flexBetween}>
                <h3>Contact US</h3>
                <img src={icFilter} alt="filter arrow" />
              </div>
            </a>
          </div>
        </div>
        <img className={mms.close} src={icClose} alt="close menu" onClick={close} />
      </>
    </Mask>
  )
}

export default Menu